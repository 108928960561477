<template>
    <SectionsNav>
        <template #introduction>
            <a class="nav-link" @click="showSection('presentation')">Обзор</a>
        </template>
        <template #programs>
            <a class="nav-link" @click="showSection('programs')">Программы</a>
        </template>
        <template #campuses>
            <a class="nav-link" @click="showSection('campus')">Кампусы</a>
        </template>
        <template #selectSection>
            <select id="select-mobile-menu" class="form-select form-select-lg" v-model="this.currentSection">
                <option value="presentation">Обзор</option>
                <option value="programs">Программы</option>
                <option value="campus">Кампусы</option>
            </select>
        </template>
    </SectionsNav>
    
    <Obzor v-if="currentSection === 'presentation'" ref="presentation" class="presentation">
        <template #title>
            Обзор
        </template>
        <template #description>  
            PSB Paris School of Business - это бизнес-школа в Париже и Ренне, предлагающая курсы BBA, MBA, MSc, MIM, DBA и курсы повышения квалификации на английском и французском языках. Школа является частью Groupe ESG, сети из семи бизнес-школ в Париже с общим количеством выпускников 26000 человек.
            Год основания школы - 1974. Кампус школы находится в 13-м округе Парижа.
        </template>
        
        <template #youtube>
        </template>
        <template #orientation>
            Bachelor & Master
        </template>
        <template #speciality>
            Finance, business, economy
        </template>
    </Obzor>


    <section v-if="currentSection === 'programs'" ref="programs" class="programs">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 title-main">
                            <h1>Программы</h1>
                        </div>
                    </div>
                    
                    <div class="row program-select-wrapper">
                        <div class="col-12 col-lg-4">
                            <select v-model="program" ref="programSelector" class="form-select form-select-lg">
                                <option disabled value="">Выбрать программу</option>
                                <option value="baa">Bachelor of Business Administration</option>
                                <option value="mim">Master in Management (MIM)</option>
                                <option value="msc">MBA/Msc in Management</option>
                            </select>
                        </div>
                    </div>
                    
                    <Program :url="'/pbs/PS_BBA.pdf'" v-if="this.program === 'baa'" id="baa">
                        <template #title>
                            Бакалавриат - Bachelor of Business Administration
                        </template>
                        <template #duree>
                            1-3 года
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                             8.900 евро/год
                        </template>
                        <template #requirements>
                            французский язык уровня B2, досье, интервью.
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            октябрь и январь
                        </template>
                    </Program>

                    <Program :url="'/pbs/PSB_MIM_Grande_Ecole.pdf'" v-if="this.program === 'mim'" id="mim">
                        <template #title>
                            Master in Management (MIM) - Grande Ecole Programme
                        </template>
                        <template #duree>
                            2-5 лет
                        </template>
                        <template #language>
                            французский или английский
                        </template>
                        <template #cost>
                            9.900-10.500 евро/год
                        </template>
                        <template #requirements>
                            язык B2, досье, интервью.
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            сентябрь
                        </template>
                        <template #salary>
                            38.000-42.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/pbs/PSB_Graduate.pdf'" v-if="this.program === 'msc'" id="msc">
                        <template #title>MBA/Msc in Management</template>
                        <template #duree>
                            2-5 лет
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            13.500-21.000 евро/год
                        </template>
                        <template #requirements>
                            английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            сентябрь, октябрь, январь
                        </template>
                        <template #salary>
                            44.000 евро/год
                        </template>
                    </Program>

                </div>
            </div>
        </div>
    </section>

    <CampusMain v-if="currentSection === 'campus'" ref="campus" class="campus">
    </CampusMain>

</template>
<script>
import Program from '@/components/schools/slots/Program.vue'
import CampusMain from '@/components/schools/slots/CampusMain.vue'
import SectionsNav from '@/components/schools/slots/SectionsNav.vue'
import Obzor from '@/components/schools/slots/Obzor.vue'
import Campus from '@/components/schools/slots/Campus.vue'

export default {
    data(){
        return {
            currentSection:"presentation",
            program : ""
        }
    },
    components:{
        Program,
        CampusMain,
        SectionsNav,
        Obzor,
        Campus
    },
    methods:{
        showSection(section){
            this.currentSection = section;
            this.desktopScroll();
        },
        desktopScroll(){
            let scrollToElement = document.querySelector(".menu")
            scrollToElement.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }
}
</script>
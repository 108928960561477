<template>
    <Introduction :url="'/brochure/pbs'" :class="'introduction-pbs'">  
        <template #title>
            Paris Business School
        </template>
        <template #speciality>
            Бизнес
        </template>
        <template #min-duration>
            От 1 года
        </template>
        <template #cost>
            От 8900 €/год
        </template>
        <template #campus>
            Париж   
        </template>
    </Introduction>  
</template>
<script>
import Introduction from '@/components/schools/slots/Introduction.vue'
export default {
    components:{
        Introduction
    }
}
</script>